import React, { useState, useEffect } from "react"
import { USA2023 } from "../util/conditionalLinks"
import PoptinTwo from "./blocks/Forms/PopupForm"
import GitexPopupModal from "./core/GitexPopupModal"

const StickyHeader = () => {
  const [gitexPopup, setGitexPopup] = useState(false)
  // const handlePopup = () => {
  //   setGitexPopup(true)
  //   sessionStorage.setItem("GitexForm", true)
  // }
  const [openGitex, setOpenGitex] = useState(false)
  const [current, setCurrent] = useState()
  const openGitexModal = () => {
    setTimeout(() => {
      setOpenGitex(true)
    }, 1000)
  }
  const closeGitexModal = () => {
    setOpenGitex(false)
  }
  useEffect(() => {
    setCurrent(window.location.pathname)
  }, [])
  return (
    <>
      {USA2023.includes(current) && (
        <GitexPopupModal open={openGitex} closeGitexModal={closeGitexModal} />
      )}
      {gitexPopup && (
        <>
          <PoptinTwo setGitexPopup={setGitexPopup} />
        </>
      )}
      <div className="px-2 h-12 bg-green-500 flex-row flex justify-center items-center w-full">
        <a
          href="/meet-us-in-gitex2024"
          onClick={openGitexModal}
          className="lg:text-center md:text-center text-left text-white lg:text-base text-xs font-sans underline"
        >
          Let’s Talk Tech, Growth, Challenges, and Ideas With Our CEO in DUBAI
          {/* Meet us at GITEX 2021 & transform your business digitally! */}
        </a>
        {/* <a
          href="/meet-us-at-ces/"
          className="lg:ml-2 md:ml-2 ml-1 lg:px-2 md:px-2 px-1 py-1 bg-white lg:text-base text-xs text-blue-500 hover:text-blue-600 font-semibold font-sans rounded text-center"
          onClick={openGitexModal} 
        >
          Schedule Meeting
        </a> */}
      </div>
    </>
  )
}

export default StickyHeader
